import Index from './Index';
import AgentReports from './AgentReports';
import LabelReports from './LabelReports';
import InboxReports from './InboxReports';
import TeamReports from './TeamReports';
import CsatResponses from './CsatResponses';
import CumulativeResponses from './CumulativeResponses';
import PinchatReports from './PinchatReports';
import SettingsContent from '../Wrapper';
import { frontendURL } from '../../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/reports'),
      component: SettingsContent,
      props: {
        headerTitle: 'REPORT.HEADER',
        icon: 'arrow-trending-lines',
        keepAlive: false,
      },
      children: [
        {
          path: '',
          redirect: 'overview',
        },
        {
          path: 'overview',
          name: 'settings_account_reports',
          roles: ['administrator'],
          component: Index,
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/reports'),
      component: SettingsContent,
      props: {
        headerTitle: 'CSAT_REPORTS.HEADER',
        icon: 'emoji',
        keepAlive: false,
      },
      children: [
        {
          path: 'csat',
          name: 'csat_reports',
          roles: ['administrator'],
          component: CsatResponses,
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/reports'),
      component: SettingsContent,
      props: {
        headerTitle: 'AGENT_REPORTS.HEADER',
        icon: 'people',
        keepAlive: false,
      },
      children: [
        {
          path: 'agent',
          name: 'agent_reports',
          roles: ['administrator'],
          component: AgentReports,
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/reports'),
      component: SettingsContent,
      props: {
        headerTitle: 'LABEL_REPORTS.HEADER',
        icon: 'tag',
        keepAlive: false,
      },
      children: [
        {
          path: 'label',
          name: 'label_reports',
          roles: ['administrator'],
          component: LabelReports,
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/reports'),
      component: SettingsContent,
      props: {
        headerTitle: 'INBOX_REPORTS.HEADER',
        icon: 'mail-inbox-all',
        keepAlive: false,
      },
      children: [
        {
          path: 'inboxes',
          name: 'inbox_reports',
          roles: ['administrator'],
          component: InboxReports,
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/reports'),
      component: SettingsContent,
      props: {
        headerTitle: 'TEAM_REPORTS.HEADER',
        icon: 'people-team',
      },
      children: [
        {
          path: 'teams',
          name: 'team_reports',
          roles: ['administrator'],
          component: TeamReports,
        },
      ],
    },
    // {
    //   path: frontendURL('accounts/:accountId/reports'),
    //   component: SettingsContent,
    //   props: {
    //     headerTitle: 'CUMULATIVE_REPORT.HEADER',
    //     icon: 'map',
    //     keepAlive: false,
    //   },
    //   children: [
    //     {
    //       path: 'cumulative',
    //       name: 'cumulative_reports',
    //       roles: ['administrator'],
    //       component: CumulativeResponses,
    //     },
    //   ],
    // },
    // {
    //   path: frontendURL('accounts/:accountId/reports'),
    //   component: SettingsContent,
    //   props: {
    //     headerTitle: 'PINCHAT_REPORTS.HEADER',
    //     icon: 'globe-desktop',
    //     keepAlive: false,
    //   },
    //   children: [
    //     {
    //       path: 'pinchat',
    //       name: 'pinchat_reports',
    //       roles: ['administrator'],
    //       component: PinchatReports,
    //     },
    //   ],
    // },
  ],
};
