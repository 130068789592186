<template>
  <div class="column content-box">
    <woot-button
      color-scheme="success"
      class-names="button--fixed-right-top"
      icon="arrow-download"
      @click="downloadAgentReports"
    >
      Download Report
    </woot-button>
    <!--  2022-02-15 -->
    <woot-button
        color-scheme="primary"
        class-names="button--fixed-right-top-second"
        icon="share"
        @click="openNew"
      >
      More
    </woot-button> 
    <report-date-range-selector @date-range-change="onDateRangeChange" />
    <div class="row">
      <woot-report-stats-card
        v-for="(metric, index) in metrics"
        :key="metric.NAME"
        :desc="metric.DESC"
        :heading="metric.NAME"
        :index="index"
        :on-click="changeSelection"
        :point="accountSummary[metric.KEY]"
        :selected="index === currentSelection"
      />
    </div>
    <div class="report-bar">
      <woot-loading-state
        v-if="accountReport.isFetching"
        :message="$t('REPORT.LOADING_CHART')"
      />
      <div v-else class="chart-container">
        <woot-bar v-if="accountReport.data.length" :collection="collection" />
        <span v-else class="empty-state">
          {{ $t('REPORT.NO_ENOUGH_DATA') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import fromUnixTime from 'date-fns/fromUnixTime';
import format from 'date-fns/format';
import ReportDateRangeSelector from './components/DateRangeSelector';

const REPORTS_KEYS = {
  CONVERSATIONS: 'conversations_count',
  INCOMING_MESSAGES: 'incoming_messages_count',
  OUTGOING_MESSAGES: 'outgoing_messages_count',
  FIRST_RESPONSE_TIME: 'avg_first_response_time',
  RESOLUTION_TIME: 'avg_resolution_time',
  RESOLUTION_COUNT: 'resolutions_count',
};

export default {
  components: {
    ReportDateRangeSelector,
  },
  data() {
    return { from: 0, to: 0, currentSelection: 0 };
  },
  computed: {
    ...mapGetters({
      accountSummary: 'getAccountSummary',
      accountReport: 'getAccountReports',
    }),
    collection() {
      if (this.accountReport.isFetching) {
        return {};
      }
      if (!this.accountReport.data.length) return {};
      const labels = this.accountReport.data.map(element =>
        format(fromUnixTime(element.timestamp), 'dd/MMM')
      );
      const data = this.accountReport.data.map(element => element.value);
      return {
        labels,
        datasets: [
          {
            label: this.metrics[this.currentSelection].NAME,
            backgroundColor: '#1f93ff',
            data,
          },
        ],
      };
    },
    metrics() {
      const reportKeys = [
        'CONVERSATIONS',
        'INCOMING_MESSAGES',
        'OUTGOING_MESSAGES',
        'FIRST_RESPONSE_TIME',
        'RESOLUTION_TIME',
        'RESOLUTION_COUNT',
      ];
      return reportKeys.map(key => ({
        NAME: this.$t(`REPORT.METRICS.${key}.NAME`),
        KEY: REPORTS_KEYS[key],
        DESC: this.$t(`REPORT.METRICS.${key}.DESC`),
      }));
    },
  },
  methods: {
    fetchAllData() {
      const { from, to } = this;
      this.$store.dispatch('fetchAccountSummary', { from, to });
      this.fetchChartData();
    },
    fetchChartData() {
      const { from, to } = this;
      this.$store.dispatch('fetchAccountReport', {
        metric: this.metrics[this.currentSelection].KEY,
        from,
        to,
      });
    },
    downloadAgentReports() {
      const { from, to } = this;
      const fileName = `agent-report-${format(
        fromUnixTime(to),
        'dd-MM-yyyy'
      )}.csv`;
      this.$store.dispatch('downloadAgentReports', { from, to, fileName });
    },
    changeSelection(index) {
      this.currentSelection = index;
      this.fetchChartData();
    },
    onDateRangeChange({ from, to }) {
      this.from = from;
      this.to = to;
      this.fetchAllData();
    }, 
    openNew() {   

       //  2022-02-15 
      // External link for reporting section
      var userCookieData = getCookie('user');
      userCookieData = JSON.parse(userCookieData);

      // 2023-10-19
       // Get the current URL
      // const url = window.location.href;
       var accountNumber = getAccountNo();
      //window.open("https://142.93.212.205:8085/dashboard/"+userCookieData.access_token+"&"+userCookieData.account_id, "_blank");
      //window.open("https://reports.pinchat.cloud/dashboard/"+userCookieData.access_token+"&"+userCookieData.account_id, "_blank");
         window.open("https://reports.pinchat.cloud/dashboard/"+userCookieData.access_token+"&"+accountNumber, "_blank");
    },
  },
};


function getAccountNo()
{
    // Get the current URL
    const currentURL = window.location.href;

    // Use regular expressions to extract the number from the URL
    const match = currentURL.match(/\/accounts\/(\d+)/);
 let accountNumber = 0;
    // Check if a match was found
    if (match && match[1]) {
       accountNumber = match[1];
      console.log(accountNumber); // This will log "85" to the console
      return accountNumber;
    } else {
      console.log("Account number not found in the URL.");
        return accountNumber;
    }

}


function getCookie(cname) {
  // 2022-02-15 
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
</script>
<style scoped lang="scss">
.button--fixed-right-top-second {
  position: fixed;
  right: 25.0rem;
  top: 0.8rem;
}
</style>
