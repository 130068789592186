<template>
  <div class="cumulative--table-container">
    <woot-button
      color-scheme="success"
      class-names="button--fixed-right-top"
      icon="arrow-download"
      @click="downloadCumulativeReport"
    >
      Download Cumulative Report
    </woot-button>   
    <ve-table
      max-height="calc(100vh - 35rem)"
      :fixed-header="true"
      :columns="columns"
      :table-data="tableData"
      :sort-option="sortOption"
    />
    <div v-show="!tableData.length" class="cumulative--empty-records">
      {{ $t('CUMULATIVE_REPORT.NO_RECORDS') }}
    </div>
    <div v-if="conversations.length" class="table-pagination">
      <ve-pagination
        :total="totalCount"
        :page-index="pageIndex"
        :page-size="25"
        :page-size-option="[25]"
        @on-page-number-change="onPageNumberChange"
      />
    </div>
  </div>
</template>
<script>
import { VeTable, VePagination } from 'vue-easytable';
import UserAvatarWithName from 'dashboard/components/widgets/UserAvatarWithName';
import { mapGetters } from 'vuex';
import timeMixin from 'dashboard/mixins/time';
import fromUnixTime from 'date-fns/fromUnixTime';
import format from 'date-fns/format';
import { downloadCsvFile } from '../../../../../helper/downloadCsvFile';
import { jsonToCSV } from '../../../../../helper/jsonToCSV';

import moment from 'moment';

export default {
  components: {
    VeTable,
    VePagination,
  },
  mixins: [timeMixin],
  props: {       
    isLoading: {
      type: Boolean,
      default: false,
    },    
    sortParam: {
      type: String,
      default: 'name',
    },
    sortOrder: {
      type: String,
      default: 'asc',
    },
    pageIndex: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      sortConfig: {},
      sortOption: {
        sortAlways: true,
        sortChange: params => this.$emit('on-sort-change', params),
      },
    };
  },

  computed: {
    ...mapGetters({
      conversations: 'getAllConversations',
    }),
    columns() {
      return [
        {
          field: 'assignedAgent',
          key: 'assignedAgent',
          title: this.$t('CUMULATIVE_REPORT.TABLE.HEADER.AGENT_NAME'),
          align: 'left',
          sortBy: this.sortConfig.assignedAgent || '',
          width: 180,
          renderBodyCell: ({ row }) => {
            if (row.assignedAgent) {
              return (
                <UserAvatarWithName size="24px" user={row.assignedAgent} />
              );
            }
            return '---';
          },
        },
        {
          field: 'contact',
          key: 'contact',
          title: this.$t('CUMULATIVE_REPORT.TABLE.HEADER.CONTACT_NAME'),
          align: 'left',
          sortBy: this.sortConfig.contact || '',
          width: 180,
          renderBodyCell: ({ row }) => {
            if (row.contact) {
              return <UserAvatarWithName size="24px" user={row.contact} />;
            }
            return '---';
          },
        },
        {
          field: 'conversationStatus',
          key: 'conversationStatus',
          title: this.$t('Status'),
          align: 'left',
          sortBy: this.sortConfig.conversationStatus || '',
          width: 100,
        },
        {
          field: 'creationDate',
          key: 'creationDate',
          title: this.$t('Creation Date'),
          align: 'left',
          sortBy: this.sortConfig.creationDate || '',
          width: 140,
        },
        {
          field: 'resolvedDate',
          key: 'resolvedDate',
          title: this.$t('Resolved Date'),
          align: 'left',
          sortBy: this.sortConfig.resolvedDate || '',
          width: 140,
        },
        {
          field: 'converstionId',
          key: 'converstionId',
          title: this.$t('CUMULATIVE_REPORT.TABLE.HEADER.DETAILS_TEXT'),
          align: 'right',
          width: 120,
          renderBodyCell: ({ row }) => {
            const routerParams = {
              name: 'inbox_conversation',
              params: { conversation_id: row.conversationId },
            };
            return (
              <div class="text-right">
                <router-link to={routerParams}>
                  {`#${row.conversationId}`}
                </router-link>
                <div class="cumulative--timestamp" v-tooltip={row.creationDate}>
                  {row.createdAgo}
                </div>
              </div>
            );
          },
        },
      ];
    },
    tableData() {
      return this.conversations.map(response => ({
        assignedAgent: response.meta.assignee,
        contact: response.meta.sender,
        conversationStatus: response.status,
        creationDate: format(
          fromUnixTime(response.timestamp),
          'LLL d yyyy, h:mm a'
        ),
        resolvedDate: this.getResolvedDate(response),
        conversationId: response.id,
        createdAgo: this.dynamicTime(response.timestamp),
      }));
    },
    accountSummary() {
      return this.$store.getters.getAccountSummary || [];
    },
    totalCount() {
      return this.accountSummary.conversations_count;
    },
    
  },
  watch: {
    sortOrder() {
      this.setSortConfig();
    },
    sortParam() {
      this.setSortConfig();
    },
  },
  mounted() {
    this.setSortConfig();
  },
  methods: {
    getLastMessage(msges){
      return msges[msges.length - 1];
    },
    getFirstMessage(msges){
      return msges[0];
    },
    setSortConfig() {
      this.sortConfig = { [this.sortParam]: this.sortOrder };
    },
    onPageNumberChange(pageIndex) {
      this.$emit('page-change', pageIndex);
    },
    getResolvedDate(resp) {

      if (resp.status == "resolved"){
        return moment(String(this.getLastMessage(resp.messages).updated_at)).format("LLL");
      }
      return '--';
    },
    downloadCumulativeReport() {
      const fileName = `cumulative-report-${this.currentTime()}.csv`;
      var rows = jsonToCSV(this.tableData, true);
      downloadCsvFile(fileName, rows);
    },
  },
};
</script>
<style lang="scss" scoped>
.cumulative--table-container {
  display: flex;
  flex-direction: column;
  flex: 1;

  .ve-table {
    background: var(--white);

    &::v-deep {
      .ve-table-container {
        border-radius: var(--border-radius-normal);
        border: 1px solid var(--color-border) !important;
      }

      th.ve-table-header-th {
        font-size: var(--font-size-mini) !important;
        padding: var(--space-normal) !important;
      }

      td.ve-table-body-td {
        padding: var(--space-small) var(--space-normal) !important;
      }
    }
  }

  &::v-deep .ve-pagination {
    background-color: transparent;
  }

  &::v-deep .ve-pagination-select {
    display: none;
  }

  .table-pagination {
    margin-top: var(--space-normal);
    text-align: right;
  }
}

.cumulative--empty-records {
  align-items: center;
  background-color: var(--white);
  border: 1px solid var(--color-border);
  border-top: 0;
  color: var(--b-600);
  display: flex;
  font-size: var(--font-size-small);
  height: 20rem;
  justify-content: center;
  margin-top: -1px;
  width: 100%;
}

.cumulative--timestamp {
  color: var(--b-400);
  font-size: var(--font-size-small);
}
</style>
