<template>
  <div class="column content-box">    
    <report-date-range-selector @date-range-change="onDateRangeChange" />    
    <cumulative-metrics />
    <cumulative-table :page-index="pageIndex"  @page-change="onPageNumberChange" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import fromUnixTime from 'date-fns/fromUnixTime';
import format from 'date-fns/format';

import CumulativeMetrics from './components/CumulativeMetrics';
import CumulativeTable from './components/CumulativeTable';
import ReportDateRangeSelector from './components/DateRangeSelector';
import wootConstants from '../../../../constants';

const REPORTS_KEYS = {
  CONVERSATIONS: 'conversations_count',
  INCOMING_MESSAGES: 'incoming_messages_count',
  OUTGOING_MESSAGES: 'outgoing_messages_count',
  FIRST_RESPONSE_TIME: 'avg_first_response_time',
  RESOLUTION_TIME: 'avg_resolution_time',
  RESOLUTION_COUNT: 'resolutions_count',
};

export default {
  name: 'CumulativeResponses',
  components: {
    CumulativeMetrics,
    CumulativeTable,
    ReportDateRangeSelector,
  },
  data() {
    return {
      pageIndex: 1,
      from: 0,
      to: 0,
      activeStatus: wootConstants.STATUS_TYPE.ALL,
    };
  },
  computed: {
    ...mapGetters({
      accountSummary: 'getAccountSummary',
      accountReport: 'getAccountReports',
      chatLists: 'getAllConversations',
      allChatList: 'getAllStatusChats',
      conversationStats: 'conversationStats/getStats',
    }),
    conversationFilters() {
      return {
        assigneeType: wootConstants.ASSIGNEE_TYPE.ALL,
        status: this.activeStatus,
        page: this.pageIndex,
      };
    },

    conversationList() {
      let conversationList = [];
      const filters = this.conversationFilters;
      conversationList = [...this.allChatList(filters)];
      return conversationList;
    },

    collection() {
      if (this.accountReport.isFetching) {
        return {};
      }
      if (!this.accountReport.data.length) return {};
      const labels = this.accountReport.data.map(element =>
        format(fromUnixTime(element.timestamp), 'dd/MMM')
      );
      const data = this.accountReport.data.map(element => element.value);
      return {
        labels,
        datasets: [
          {
            label: this.metrics[REPORTS_KEYS['CONVERSATIONS']].NAME,
            backgroundColor: '#1f93ff',
            data,
          },
        ],
      };
    },
  },
  methods: {
    fetchAllData() {
      const { from, to } = this;
      this.$store.dispatch('fetchAccountSummary', { from, to });
      this.fetchConversations();
    },
    fetchConversations() {
      this.$store
        .dispatch('fetchAllConversations', this.conversationFilters)
        .then(() => this.$emit('conversation-load'));
    },
    onPageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.fetchAllData();
    },
    onDateRangeChange({ from, to }) {
      this.from = from;
      this.to = to;
      this.fetchAllData();
    },
  },
};
</script>
