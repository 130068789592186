export default {
  computed: {
    hostURL() {
      return window.pinchatConfig.hostURL;
    },
    vapidPublicKey() {
      return window.pinchatConfig.vapidPublicKey;
    },
    enabledLanguages() {
      return window.pinchatConfig.enabledLanguages;
    },
  },
};
