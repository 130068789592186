<template>
  <div class="row cumulative--metrics-container">
    <cumulative-metric-card
      :label="$t('Total Conversations')"
      :info-text="$t('Total number of conversations.')"
      :value="conversationsCount"
    />
    <cumulative-metric-card
      :label="$t('Open')"
      :info-text="$t('Total number of open conversations.')"
      :value="openConversationsCount"
    />
    <cumulative-metric-card
      :label="$t('Pending')"
      :info-text="$t('Total number of pending conversations.')"
      :value="pendingConversationsCount"
    />
    <cumulative-metric-card
      :label="$t('Snoozed')"
      :info-text="$t('Total number of snoozed conversations.')"
      :value="snoozedConversationsCount"
    />
    <cumulative-metric-card
      :label="$t('Resolved')"
      :info-text="$t('Total number of resolved conversations.')"
      :value="resolutionsCount"
    />

  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import CumulativeMetricCard from './CumulativeMetricCard';

export default {
  components: {
    CumulativeMetricCard,
  },
  data() {
    return {
      from: 0,
      to: 0,
      currentSelection: 0,
      selectedFilter: null,
    };
  },

  computed: {
    
    accountSummary() {
      return this.$store.getters.getAccountSummary || [];
    },

    conversationsCount() {
      return this.accountSummary.conversations_count
        ? this.accountSummary.conversations_count.toLocaleString()
        : '--';
    },
    openConversationsCount(){
      return this.accountSummary.open_count
        ? this.accountSummary.open_count.toLocaleString()
        : '--';
    },
    pendingConversationsCount(){
      return this.accountSummary.pending_count
        ? this.accountSummary.pending_count.toLocaleString()
        : '--';
    },
    snoozedConversationsCount(){
      return this.accountSummary.snoozed_count
        ? this.accountSummary.snoozed_count.toLocaleString()
        : '--';
    },
    resolutionsCount() {
      return this.accountSummary.resolutions_count
        ? this.accountSummary.resolutions_count.toLocaleString()
        : '--';
    }
  },
};
</script>
<style lang="scss" scoped>
.cumulative--metrics-container {
  background: var(--white);
  margin-bottom: var(--space-two);
  border-radius: var(--border-radius-normal);
  border: 1px solid var(--color-border);
  padding: var(--space-normal);
}

.emoji--distribution {
  display: flex;
  justify-content: flex-end;

  .emoji--distribution-item {
    padding-left: var(--space-normal);
  }
}

.emoji--distribution-chart {
  margin-top: var(--space-small);
}

.emoji--distribution-key {
  margin-right: var(--space-micro);
}
</style>
