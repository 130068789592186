<template>
  <div class="column content-box">    
    <report-filters
      v-if="filterItemsList"
      :type="type"
      :filter-items-list="filterItemsList"
      @date-range-change="onDateRangeChange"
      @filter-change="onFilterChange"
    />
    <!-- <report-date-range-selector @date-range-change="onDateRangeChange" /> -->
    <pinchat-metrics />
    <pinchat-table :page-index="pageIndex" @page-change="onPageNumberChange" />
  </div>
</template>
<script>
import PinchatMetrics from './components/PinchatMetrics';
import PinchatTable from './components/PinchatTable';
import ReportDateRangeSelector from './components/DateRangeSelector';

import ReportFilters from './components/ReportFilters';
import fromUnixTime from 'date-fns/fromUnixTime';
import format from 'date-fns/format';

const REPORTS_KEYS = {
  CONVERSATIONS: 'conversations_count',
  INCOMING_MESSAGES: 'incoming_messages_count',
  OUTGOING_MESSAGES: 'outgoing_messages_count',
  FIRST_RESPONSE_TIME: 'avg_first_response_time',
  RESOLUTION_TIME: 'avg_resolution_time',
  RESOLUTION_COUNT: 'resolutions_count',
  OPEN_COUNT: 'open_count',
  PENDING_COUNT: 'pending_count',
  SNOOZED_COUNT: 'snoozed_count',
};

export default {
  name: 'PinchatReports',
  components: {
    PinchatMetrics,
    PinchatTable,
    ReportDateRangeSelector,
    ReportFilters,
  },
  props: {
    type: {
      type: String,
      default: 'agent',
    },
    getterKey: {
      type: String,
      default: 'agents/getAgents',
    },
    actionKey: {
      type: String,
      default: 'agents/get',
    }
  },
  data() {
    return {
      pageIndex: 1,
      from: 0,
      to: 0,
      currentSelection: 0,
      selectedFilter: null,
    };
  },
  computed: {
    filterItemsList() {
      return this.$store.getters[this.getterKey] || [];
    },
    accountSummary() {
      return this.$store.getters.getAccountSummary || [];
    },
    accountReport() {
      return this.$store.getters.getAccountReports || [];
    },
    collection() {
      if (this.accountReport.isFetching) {
        return {};
      }
      if (!this.accountReport.data.length) return {};
      const labels = this.accountReport.data.map(element =>
        format(fromUnixTime(element.timestamp), 'dd/MMM')
      );
      const data = this.accountReport.data.map(element => element.value);
      return {
        labels,
        datasets: [
          {
            label: this.metrics[this.currentSelection].NAME,
            backgroundColor: '#1f93ff',
            data,
          },
        ],
      };
    },
    metrics() {
      let reportKeys = ['CONVERSATIONS'];
      // If report type is agent, we don't need to show
      // incoming messages count, as there will not be any message
      // sent by an agent which is incoming.
      if (this.type !== 'agent') {
        reportKeys.push('INCOMING_MESSAGES');
      }
      reportKeys = [
        ...reportKeys,
        'OUTGOING_MESSAGES',
        'FIRST_RESPONSE_TIME',
        'RESOLUTION_TIME',
        'RESOLUTION_COUNT',
      ];
      return reportKeys.map(key => ({
        NAME: this.$t(`REPORT.METRICS.${key}.NAME`),
        KEY: REPORTS_KEYS[key],
        DESC: this.$t(`REPORT.METRICS.${key}.DESC`),
      }));
    },
  },
  methods: {
    fetchAllData() {
      if (this.selectedFilter) {
        const { from, to } = this;
        this.$store.dispatch('fetchAccountSummary', {
          from,
          to,
          type: this.type,
          id: this.selectedFilter.id,
        });
        this.fetchChartData();
      }
    },
    fetchChartData() {
      const { from, to } = this;
      this.$store.dispatch('fetchAccountReport', {
        metric: this.metrics[this.currentSelection].KEY,
        from,
        to,
        type: this.type,
        id: this.selectedFilter.id,
      });
    },
    getAllData() {
      this.$store.dispatch('pinchat/getMetrics', {
        from: this.from,
        to: this.to,
      });
      this.getResponses();
    },
    getResponses() {
      this.$store.dispatch('pinchat/get', {
        page: this.pageIndex,
        from: this.from,
        to: this.to,
      });
    },
    onPageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getResponses();
    },
    onDateRangeChange({ from, to }) {
      this.from = from;
      this.to = to;
      this.getAllData();
    },
    onFilterChange(payload) {
      if (payload) {
        this.selectedFilter = payload;
        this.fetchAllData();
      }
    },
  },
};
</script>
