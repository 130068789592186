<template>
  <div class="row pinchat--metrics-container">
    <pinchat-metric-card
      :label="$t('PINCHAT_REPORTS.METRIC.TOTAL_CONVERSATIONS.LABEL')"
      :info-text="$t('PINCHAT_REPORTS.METRIC.TOTAL_CONVERSATIONS.TOOLTIP')"
      :value="conversationsCount"
    />
    <!-- <pinchat-metric-card
      :label="$t('PINCHAT_REPORTS.METRIC.TOTAL_RESPONSES.LABEL')"
      :info-text="$t('PINCHAT_REPORTS.METRIC.TOTAL_RESPONSES.TOOLTIP')"
      :value="responseCount"
    /> -->
    <!-- <pinchat-metric-card
      :label="$t('PINCHAT_REPORTS.METRIC.SATISFACTION_SCORE.LABEL')"
      :info-text="$t('PINCHAT_REPORTS.METRIC.SATISFACTION_SCORE.TOOLTIP')"
      :value="formatToPercent(satisfactionScore)"
    /> 
    <pinchat-metric-card
      :label="$t('PINCHAT_REPORTS.METRIC.RESPONSE_RATE.LABEL')"
      :info-text="$t('PINCHAT_REPORTS.METRIC.RESPONSE_RATE.TOOLTIP')"
      :value="formatToPercent(responseRate)"
    /> -->
    <!-- <div v-if="metrics.totalResponseCount" class="medium-6 report-card">
      <h3 class="heading">
        <div class="emoji--distribution">
          <div
            v-for="(rating, key, index) in ratingPercentage"
            :key="rating + key + index"
            class="emoji--distribution-item"
          >
            <span class="emoji--distribution-key">{{
              pinchatRatings[key - 1].emoji
            }}</span>
            <span>{{ formatToPercent(rating) }}</span>
          </div>
        </div>
      </h3>
      <div class="emoji--distribution-chart">
        <woot-horizontal-bar :collection="chartData" :height="24" />
      </div>
    </div> -->
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import PinchatMetricCard from './PinchatMetricCard';
import { CSAT_RATINGS } from 'shared/constants/messages';

export default {
  components: {
    PinchatMetricCard,
  },
  data() {
    return {
      pinchatRatings: CSAT_RATINGS,
    };
  },
  computed: {
    
    ...mapGetters({
      metrics: 'csat/getMetrics',
      ratingPercentage: 'csat/getRatingPercentage',
      satisfactionScore: 'csat/getSatisfactionScore',
      responseRate: 'csat/getResponseRate',
    }),
    accountSummary() {
      return this.$store.getters.getAccountSummary || [];
    },

    chartData() {
      return {
        labels: ['Time Spent'],
        datasets: CSAT_RATINGS.map((rating, index) => ({
          label: rating.emoji,
          data: [this.ratingPercentage[index + 1]],
          backgroundColor: rating.color,
        })),
      };
    },
    conversationsCount() {
      return this.accountSummary.conversations_count
        ? this.accountSummary.conversations_count.toLocaleString()
        : '--';
    },
  },
  methods: {

    formatToPercent(value) {
      return value ? `${value}%` : '--';
    },
  },
};
</script>
<style lang="scss" scoped>
.pinchat--metrics-container {
  background: var(--white);
  margin-bottom: var(--space-two);
  border-radius: var(--border-radius-normal);
  border: 1px solid var(--color-border);
  padding: var(--space-normal);
}

.emoji--distribution {
  display: flex;
  justify-content: flex-end;

  .emoji--distribution-item {
    padding-left: var(--space-normal);
  }
}

.emoji--distribution-chart {
  margin-top: var(--space-small);
}

.emoji--distribution-key {
  margin-right: var(--space-micro);
}
</style>
